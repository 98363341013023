<template>
  <div class="adverts-list">
    <error :errors="advertsError" />
    <b-overlay :show="formBusy" rounded="sm">
      <portlet fluidHeight>
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <b-pagination
              class="mb-0 mr-2"
              v-model="advertsMeta.current_page"
              :total-rows="advertsMeta.total"
              :per-page="advertsMeta.per_page"
              aria-controls="adverts"
              @change="onChangePage"
            />
            <div class="actions">
              <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
                <i class="flaticon2-refresh-arrow"></i>
              </button>
              <b-button :to="{name: 'adverts.create'}" class="btn btn-label-success btn-sm border-0">
                يېڭىدىن قوشۇش
              </b-button>
            </div>
          </div>
        </template>
      </portlet>
      <b-row>
        <b-col v-for="advert in adverts" md="3" :key="advert.id" sm="6">
          <portlet title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:toolbar>
              <b-form-checkbox v-b-tooltip title="ھالەت تەڭشىكى" :key="`${advert.id}_show`" switch @change="onSwitchShow(advert.id)" v-model="advert.is_show"/>
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="deleteItem(advert.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media">
                    <b-img class="kt-widget__img" :class="{'kt-hidden': ! advert.cover_image_preview}" rounded="" height="50" :src="advert.cover_image_preview" alt="image" />
                    <div :class="{'kt-hidden': advert.cover_image_preview}" class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest">
                      {{ advert.title }}
                    </div>
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ advert.title }}
                    </a>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">تىپى: </span>
                      <span class="kt-widget__data" v-if="advert.source === 'category'">تۈرگە ئاتلايدۇ</span>
                      <span class="kt-widget__data" v-if="advert.source === 'author'">يازغۇچىغا ئاتلايدۇ</span>
                      <span class="kt-widget__data" v-if="advert.source === 'article'">ئەسەرگە ئاتلايدۇ</span>
                      <span class="kt-widget__data" v-if="advert.source === 'miniprogram'">ئەپچاققا ئاتلايدۇ</span>
                      <span class="kt-widget__data" v-if="advert.source === 'external_link'">سىرتقى ئۇلانمىغا ئاتلايدۇ</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label" v-if="advert.source === 'category'">تۈر نامى: </span>
                      <span class="kt-widget__label" v-if="advert.source === 'author'">ئاپتور ئىسمى: </span>
                      <span class="kt-widget__label" v-if="advert.source === 'article'">ئەسەر نامى: </span>
                      <span class="kt-widget__label" v-if="advert.source === 'miniprogram'">ئەپچاق ئادېرسى: </span>
                      <span class="kt-widget__label" v-if="advert.source === 'external_link'">ئۇلانما ئادېرسى: </span>
                      <span class="kt-widget__data" v-if="advert.source === 'category'">{{ advert.chain_related ? advert.chain_related.title : 'تۈر ئۆچۈرۈلگەن بولىشى مۇمكىن' }}</span>
                      <span class="kt-widget__data" v-if="advert.source === 'author'">{{ advert.chain_related ? advert.chain_related.title : 'ئاپتور ئۆچۈرۈلگەن بولىشى مۇمكىن' }}</span>
                      <span class="kt-widget__data" v-if="advert.source === 'article'">{{ advert.chain_related ? advert.chain_related.title : 'ئەسەر ئۆچۈرۈلگەن بولىشى مۇمكىن' }}</span>
                      <span class="kt-widget__data" v-if="advert.source === 'miniprogram'">{{ advert.chain }}</span>
                      <span class="kt-widget__data" v-if="advert.source === 'external_link'">
                        <b-button :href="advert.chain" target="_blank" variant="primary">بېسىپ كۆرۈڭ</b-button>
                      </span>
                    </div>
                  </div>
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">باشلىنىش ۋاقتى: </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ advert.s_time }}</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ئاياقلىشىش ۋاقتى: </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ advert.e_time }}</span>
                    </div>
                  </div>
                </div>
                <div class="kt-widget__footer">
                  <b-button :to="{name: 'adverts.edit', params: {id: advert.id}}" type="button" variant="primary" class="btn btn-lg btn-upper">تەھرىرلەش</b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <portlet v-if="adverts.length < 1" class="kt-callout">
        <template v-slot:body>
          <div class="kt-callout__body">
            <div class="kt-callout__content">
              <h3 class="kt-callout__title">ئۇچۇر تېپىلمىدى</h3>
              <p class="kt-callout__desc">
                مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن ئۇچۇر قوشۇڭ
              </p>
            </div>
            <div class="kt-callout__action">
              <b-button :to="{name: 'adverts.create'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-success">يېڭىدىن قوشۇش</b-button>
            </div>
          </div>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../partials/widgets/Error";
  import Portlet from "../../../partials/content/Portlet";
  import { mapGetters } from "vuex";
  import { GET_ADVERTS_LIST } from "../../../../store/types";
  import advertsMixin from "../../../../mixins/advertsMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";

  export default {
    name: "index",
    components: { Error, Portlet },
    mixins: [ advertsMixin, formBusyMixin ],
    computed: {
      ...mapGetters(["advertsError", "adverts", "advertsMeta"])
    },
    created() {
      this.getAdvertsList();
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئېلان باشقۇرۇش", route: "list" },
        { title: "بارلىق ئېلانلار" }
      ]);
    },
    data(){
      return {
        formBusy: false
      };
    },
    methods: {
      getAdvertsList(page = 1){
        this.showBusy()
        this.$store.dispatch(GET_ADVERTS_LIST, page)
          .then(()=>{
            this.hideBusy()
          })
          .catch(()=>{
            this.hideBusy()
          })
      },
      onChangePage(page){
        this.getAdvertsList(page);
      },
      deleteItem(id) {
        this.$bvModal.msgBoxConfirm('مەزكۇر ئېلاننى ئ‍ۆچۈرسىڭىز ئەسلىگە قايتۇرغىلى بولمايدۇ، ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟', {
          title: 'راستىنلا ئۆچۈرەمسىز ؟',
          okVariant: 'danger',
          okTitle: 'جەزىملەشتۈرىمەن',
          cancelTitle: 'ياق',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if ( ! value ) return;
          this.deleteAdverts(id)
            .then(()=>{
              this.refreshList();
            })
        })
        .catch(err => {
          console.log(err);
          // An error occurred
        })
      },
      refreshList(){
        let page = this.advertsMeta.current_page;
        this.getAdvertsList(page);
      },
      onSwitchShow(id){
        this.switchAdvertsState(id, {column: 'is_show'})
      },
    }
  }
</script>
<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 74px;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
</style>
