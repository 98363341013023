import ApiService from "../common/api.service";
import handleReject from "../common/handleReject";
import { SET_ADVERTS_ERROR } from "../store/types";

export default {
  methods: {
    deleteAdverts(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/manager/advert/${id}`)
        .then(response=>{
          this.hideBusy();
          this.$store.commit(SET_ADVERTS_ERROR, null);
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_ADVERTS_ERROR);
          reject(response);
        })
      })
    },
    switchAdvertsState(id, params = {}, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(`/manager/advert/${id}/switch`, params)
        .then(response=>{
          busy && this.hideBusy();
          this.$store.commit(SET_ADVERTS_ERROR, null);
          resolve(response);
        })
        .catch(response=>{
          busy && this.hideBusy();
          handleReject(this.$store, response, SET_ADVERTS_ERROR);
          reject(response);
        })
      })
    },
  }
}
